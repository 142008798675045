.table-scroll {
  width: 100%;
  overflow-x: scroll;
}
.table-scroll-y {
  max-height: 100%;
  overflow-y: scroll;
  margin-right: -10px;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e9e5dd;
  }
  &--sm {
    height: 200px;
  }
  &--md {
    height: 300px;
  }
  &--lg {
    height: 400px;
  }
  &--xl {
    height: 500px;
  }
}

.table {
  // min-width: 700px;
  border-collapse: collapse;
  .form-checkbox label:before {
    margin-right: 0;
  }
}

.table-head {
  color: #3f3f3f;
  display: flex;
  font-weight: 500;
  .table-col {
    background: #eff1f2;
    border-radius: 5px;
  }
}

.table-row {
  display: flex;
  border-bottom: 1px solid #eff1f2;
  min-height: 60px;
  &.is-active {
    .table-col__expand {
      max-height: 500px;
    }
  }
  &__delete {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &.in-active {
    .table-col {
      pointer-events: none;
    }
    &:hover {
      .table-row__delete {
        opacity: 1;
        z-index: 100;
        pointer-events: all;
        background: #a31318ad;
        color: #fff;
      }
    }
  }
}

.table-col {
  padding: 5px;
  &--pad-md {
    padding: 15px 10px;
  }
  &--pad-lg {
    padding: 20px 10px;
  }
  &--bordered {
    border-right: 1px solid #e8e8e8;
  }
  &:last-child {
    border-right: none;
  }
  &--3xs {
    width: 65px;
  }
  &--xxs {
    width: 80px;
  }
  &--xs {
    width: 110px;
  }
  &--sm {
    width: 150px;
  }
  &--md {
    width: 220px;
  }
  &--lg {
    width: 260px;
  }
  &--xl {
    width: 300px;
  }
  &--2xl {
    width: 340px;
  }
  &--auto {
    flex: 1;
  }
  &__expand-title {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__expand {
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s;
  }
}

// table loader
.table-loader {
  display: none;
}
.table--loadable {
  &.is-loading {
    .table-loader {
      margin: 0 auto;
      display: block;
    }
    .table-content {
      height: 300px;
      opacity: 0;
      pointer-events: none;
    }
    .table-row {
      display: none;
    }
  }
}

.table-col {
  &.col-lg-18 {
    width: 100%;
  }
  &.col-lg-17 {
    width: 94.44444444%;
  }
  &.col-lg-16 {
    width: 88.88888889%;
  }
  &.col-lg-15 {
    width: 83.33333333%;
  }
  &.col-lg-14 {
    width: 77.77777778%;
  }
  &.col-lg-13 {
    width: 72.22222222%;
  }
  &.col-lg-12 {
    width: 66.66666667%;
  }
  &.col-lg-11 {
    width: 61.11111111%;
  }
  &.col-lg-10 {
    width: 55.55555556%;
  }
  &.col-lg-9 {
    width: 50%;
  }
  &.col-lg-8 {
    width: 44.44444444%;
  }
  &.col-lg-7 {
    width: 38.88888889%;
  }
  &.col-lg-6 {
    width: 33.33333333%;
  }
  &.col-lg-5 {
    width: 27.77777778%;
  }
  &.col-lg-4 {
    width: 22.22222222%;
  }
  &.col-lg-3 {
    width: 16.66666667%;
  }
  &.col-lg-2 {
    width: 11.11111111%;
  }
  &.col-lg-1 {
    width: 5.55555556%;
  }
}
