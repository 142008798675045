.side-drawer{
  position:fixed;
  left:0;
  top:0;
  background:#fff;
  height: 100%;
  width: 300px;
  z-index: 100;
  padding: 30px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar{width: 4px}
  &::-webkit-scrollbar-track{background: #fff}
  &::-webkit-scrollbar-thumb {
    background: $sec;
    border-radius: 5px
  }
  &::-webkit-scrollbar-thumb:hover{filter:brightness(0.8)}
}

.navigation-items{
  list-style:none;
  padding:0;
  &--bottom{
    margin-top: auto;
    margin-bottom: auto;
  }
}

.site-nav{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.logo{
  width: 100%;
  max-width: 250px;
  &__mobile{display:none}
  &__signature{
    color: $main;
  }
}

.navigation-item{
  background: $sec;
  color:#3F3F3F;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  line-height: 20px;
  margin: 5px 0;
  transition: background 0.4s;
  &__icon{
    margin-right: 10px;
  }
  &__title{
    display: flex;
    align-items: center;
    &.is-active{
      color:$sec;
    }
    &--medium{
      padding: 5px 0;
      display:block;
    }
	}
  &.is-active,
  &:hover{
    background: $main;
    color: #fff;
	}
	&--disabled{
		pointer-events: none;
    background: #EFF1F2;
	}
}

@media only screen and (max-width: 1200px) {
  .navigation-item__name, .navigation-item__angle{
    max-width:0;
    overflow: hidden;
    max-height: 30px;
  }
  .navigation-item__title{display:none}
  .navigation-item__icon{margin-right:0}
  .side-drawer{padding: 5px; width:60px;}
  .navigation-item{position: relative;user-select: none;-webkit-tap-highlight-color:rgba(0,0,0,0);}
  .logo{
    &__desktop{display:none}
    &__mobile{display:block; .logo__image{padding: 3px;}}
  }
  .side-drawer__avatar {
    width:45px;
    height:45px;
    .icon-round{max-width:30px;max-height:30px}
  }
}