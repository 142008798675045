@import "tailwindcss/utilities.css";

@import "abstracts/variables";
@import "abstracts/functions";
@import "abstracts/mixins";

@import "vendors/custom-bootstrap";
@import "vendors/helpers";
@import "vendors/lazy-load";
@import "vendors/choices-main";
@import "vendors/flatpickr";
@import "vendors/flatpickr-light";

@import "base/animations";
@import "base/base";
@import "base/typography";
@import "base/utilities";
@import "base/global";

@import "components/button";
@import "components/choices";
@import "components/modal";
@import "components/icon";
@import "components/tile";
@import "components/notification";
@import "components/accordion";
@import "components/table";
@import "components/pagination";
@import "components/loader";
@import "components/gallery-item";
@import "components/tabs";

@import "layout/footer";
@import "layout/forms";
@import "layout/top-bar";
@import "layout/side-drawer";

@import "pages/auth";

@import "../../../node_modules/jodit/build/jodit.min.css";

.attachment-file{
	border: 1px solid transparent;
	&.is-active{
		border-color: #990C00
	}
}
.truncate{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.flex\!{
	display:flex;
}

.notify-wrapper{
	@apply fixed bottom-0 z-100 right-0 m-10;
}
.notify-item{
	@apply border-l-1 p-4 m-4 transition-all duration-300;
	&--success{
		@apply bg-green-300 border-green-500 text-white;
	}
	&--error{
		@apply bg-red-300 border-red-500 text-white;
	}

}