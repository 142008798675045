.icon{
	object-fit: contain;
	width:100%;
	height:100%;
	user-select: none;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	max-width: 15px;
	max-height: 15px;
	&--xs{
		max-width:20px;
		max-height:20px;
	}
	&--sm{
		max-width:40px;
		max-height:40px;
	}
	&--md{
		max-width:50px;
		max-height:50px;
	}
	&--lg{
		max-width:100px;
		max-height:100px;
	}
	&--xl{
		max-width:150px;
		max-height:150px;
	}
	&--xxl{
		max-width:250px;
		max-height:250px;
	}

	&--hover{
		cursor:pointer;
		opacity: 0.3;
		&:hover{
			opacity: 1;
		}
	}
}

.icon-rect{
	background: $main;
	border-radius: 5px;
	width: 44px;
	height: 44px;
	&--hover{
		transition: background-color 0.4s;
		cursor: pointer;
		&:hover, &.is-active{
			background: #E9E5DD;
		}
	}
}

.icon-oblong{
	background: $main;
	border-radius: 5px;
	padding: 8px;
	height: 44px;
	color: #fff;
	&--hover{
		transition: background-color 0.4s;
		cursor: pointer;
		&:hover, &.is-active{
			background: #E9E5DD;
		}
	}
}

.icon-round-wrapper{
	width:200px;
	height:200px;
	background: #EFF1F2;
	border-radius:50%;
	&--xs{
		width: 40px;
		height: 40px;
	}
	&--md{
		width: 120px;
		height: 120px;
	}
}
.icon-round{
	border-radius:50%;
	display:flex;
	align-items:center;
	justify-content: center;
	flex:none;
	user-select: none;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	position: relative;
	&.blue{background:$sec}
	
	&__count{
		position: absolute;
    background: #D25050;
    border-radius: 30px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: #fff;
		bottom: -5px;
    right: -5px;
    &--blue{
      background: $main;
    }
	}
	&--xs{
		width: 20px;
		height: 20px;
	}
	&--sm{
		width: 30px;
    height: 30px;
		border: 1px solid #BFC4C5;
		&__image{
			max-width:50%;
			max-height:50%;
		}
	}
	&--md{
		width:50px;
		height:50px;
		border:1px solid #BFC4C5;
		&__image{
			max-width:50%;
			max-height:50%;
		}
	}
	&--lg{
		width:75px;
		height:75px;
		&__image{
			max-width:50%;
			max-height:50%;
		}
	}
	&--xl{
		width:100px;
		height:100px;
	}
	&--blue{
		background: $sec;
		img{
			filter: invert(1) brightness(111);
		}
	}
	&--white{
		background: #fff;
	}
	&--hoverable{
		cursor: pointer;
		&:hover{
			background: $sec;
			img{
				filter:brightness(0) invert(1);
			}
		}
	}
	&.is-fit{
		object-fit: cover;
    width: 100%;
    height: 100%;
	}
}

.delete-icon{
  &--sm{
    width:30px;
  }
  &--md{
    width: 35px;
  }
  flex: none;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.is-inactive{
	opacity: 0.3
}