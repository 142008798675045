.tile{
  background:#fff;
  border-radius:5px;
  height: 100%;
  box-shadow: 0 3px 6px #00000002, 0 3px 6px #00000003;
  position:relative;

  &--height-auto{
    height:auto
  }
  &--padded{
    padding:20px;
  }
  &--double-padded{
    padding: 40px;
  }
  &__title{
    @include fluid-type(20px, 24px);
    &--medium{
      @include fluid-type(16px, 20px);
    }
    &--large{
      @include fluid-type(26px, 35px);
    }
    font-weight:700;
  }

  &--user-pane{
    height:340px;
  }

  &.is-active{
    cursor:pointer;
    background: $sec;
    h6{color:#fff;}
    .icon-round--blue{
      background:#fff;
      img{filter:none}
    }
  }




  &--half-top{
    height: calc( 50% - 1.5vmin);
  }
  &--half-bot{
    margin-top: 3vmin;
    height: calc( 50% - 1.5vmin);
  }
  &--border{
    border: 1px solid #E2E4E5;
  }
  &--gray{
    background: #EFF1F2;
  }





  &--hoverable-border{
    transition:all 0.4s;
    &:hover{
      border-color: $main;
    }
  }

  &--hoverable{
    transition:all 0.4s;
    &:hover, &.is-active{
      color:#fff;
      background:$sec;
      box-shadow: 0px 4px 10px #0c0c0c5c;
      .tile__title{
        color: #fff;
        &--large{
          color: #fff;
        }
      }
      .tile-list{
        color:#fff;
      }
      .icon{
        filter: brightness(0) invert(1);
      }
      &.tile--bundle-pane{
        .tile__image-mask, .tile__bg-image{opacity:1}
      }
    }
  }
}


@media only screen and (max-width: 756px) {

}
@media only screen and (max-width: 640px) {

}
@media only screen and (max-width: 575px) {

}