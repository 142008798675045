.accordion{
  border:1px solid #f2f2f2;
  border-radius:10px;
  padding:0;
  list-style:none;
  &__item{
    background: #fff;
    border-radius: 10px;
  }
  &__header{
    border-radius: 10px;
      background:#fff;
      padding: 20px;
      font-weight:bold;
      font-size:20px;
      border-bottom:1px solid rgb(219, 219, 219);
      cursor: pointer;
      display:flex;
      align-items:center;
  }

  &__icon{
      position: relative;
      width: 20px;
      height: 20px;
      margin-right:10px;
      &:before,
      &:after{
        content: "";
        position: absolute;
        background-color: $sec;
        transition: transform 0.25s ease-out;
      }
      &:before{
          top: 0;
          left: 50%;
          width: 4px;
          height: 100%;
          margin-left: -2px;
      }
      &:after{
          top: 50%;
          left: 0;
          width: 100%;
          height: 4px;
          margin-top: -2px;
      }
  }

  &__body{
      padding:0;
      max-height:0;
      overflow:hidden;
      transition:all 0.4s;
  }
  &__item.is-active{
      .accordion__body{
          max-height:500px
      }
      .accordion__icon{
          &:before{ transform: rotate(90deg); }
          &:after{ transform: rotate(180deg); }
      }
  }
  &__desc{
      padding:2vmin;
  }
}