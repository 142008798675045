.transition{
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

// Underline From Center
.hufc {
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hufc:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 51%;
	right: 51%;
	bottom: 0;
	background: #000;
	height: 1px;
	-webkit-transition-property: left, right;
	transition-property: left, right;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hufc:hover:before,
.hufc:focus:before,
.hufc:active:before {
	left: 0;
	right: 0
}

// Underline From Left
.hufl {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
	&:before{
		background: $main;
	}
	&--white:before{
		background: #fff;
	}
}
.hufl:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 100%;
	bottom: 0;
	height: 1px;
	-webkit-transition-property: right;
	transition-property: right;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.hufl:hover:before, .hufl:focus:before, .hufl:active:before {
	right: 0;
}