.btn {
	padding: 1.2rem 2.4rem;
	font-size: 16px;
	display: inline-block;
	color: #fff;
	transition: background 0.4s, transform 0.4s;
	font-weight: 300;
	cursor:pointer;
	text-align:center;
	border:none;
	outline:none;
	border-radius: 5px;
	border:2px solid transparent;
	line-height: 20px;
	user-select: none;
	&--red{
		background: $main;
		color: #fff;
	}
	&--green{
		background: $third;
		color: #fff;
	}
	&:hover{
		background: $sec
	}
	&.is-hidden{
		display:none;
	}
	&--disabled{
		pointer-events:none;
		background: #EFF1F2;
	}
}


.btn--edit-wrapp{
	position:relative;
	.edit-square{height:100%}
	.btn{
		opacity: 0.2;
		pointer-events: none;
	}
	&.is-active{
		.btn{
			opacity: 1;
			pointer-events: all;
		}
		.edit-square{
			transform: translateX(50px) scale(1.5);
			opacity: 0;
			pointer-events: none;
		}
	}
}

.edit-square{
	position: absolute;
	right: 0;
	transition: all 0.4s;
	cursor: pointer;
}

.btn--sm{
	@apply px-4 py-2 text-sm;
}