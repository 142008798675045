.form {
  width: 100%;

	&.is-inactive{
		opacity:0.3;
		pointer-events: none;
	}
  &--wider {
    max-width: 800px;
  }
}

label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* -------------------------------------------------------------------------- */
/*                               TEXT & TEXTAREA                              */
/* -------------------------------------------------------------------------- */
.form-text {
  position: relative;
  display: block;
  font-size: $standard_font_size;
  line-height: 1.5;

  &>input,
  &>textarea {
    display: block;
    box-sizing: border-box;
    color: $text_color;
    padding: 14.5px 16px;
    border-radius: 5px;
    border: 1px solid #D2D7D8;
    width: 100%;

    &:focus {
      border: 1px solid rgb(162, 163, 163);
    }

    &:disabled {
      background-color: #fff;
    }

    &.is-invalid {
      border-color: $main
    }

    &::placeholder {
      color: rgb(119, 119, 119);
    }
  }

  &>textarea {
    resize: none;
    height: 120px;
    border-radius: 13px;
  }
}


/* -------------------------------------------------------------------------- */
/*                                    RADIO                                   */
/* -------------------------------------------------------------------------- */
.form-radio {
  position: relative;
  line-height: 20px;

  label {
    display: inline-flex;
    cursor: pointer;

    &:before {
      content: '';
      flex: none;
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 5px;
      margin-right: 10px;
      border: 1px solid #D2D7D8;
      transition-duration: 0.1s;
    }

    &:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 4px;
      flex: none;
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 3px;
      margin-right: 10px;
      background: $main;
      transition-duration: 0.1s;
      transform: scale(0);
      z-index: 2;
    }
  }

	// &-radio input:checked {
  //   background: $main;
  //   transform: scale(1);
  // }

  input {
    position: absolute;
    width: 35px;
    height: 35px;
    left: -8px;
    top: -8px;
    border-radius: 50%;
    appearance: none;
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    z-index: -1;

    &:checked {
      background: $main;
      transform: scale(1);
    }

    &:focus {
      opacity: .15;
    }

    &:checked~label:before {
      border-color: $main;
    }

    &:checked~label:after {
      transform: scale(1)
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                  CHECKBOX                                  */
/* -------------------------------------------------------------------------- */
.form-checkbox {
  position: relative;

  label {
    display: inline-flex;
    cursor: pointer;
    line-height: 1.4;
    font-size: 15px;

    &:before {
      content: '';
      flex: none;
      width: 18px;
      height: 18px;
      display: inline-block;
      border-radius: 5px;
      margin-right: 10px;
      border: 1px solid #3F3F3F;
      transition-duration: 0.1s;
    }

    &:after {
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      width: 10px;
      height: 6px;
      display: block;
      border: 2px solid $main;
      border-top: none;
      border-right: none;
      transform: translate(3px, 4px) rotate(-45deg);
      z-index: 3;
      opacity: 0;
    }
  }

  input {
    position: absolute;
    width: 36px;
    height: 36px;
    left: -9px;
    top: -8px;
    border-radius: 50%;
    appearance: none;
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    z-index: -1;

    &:focus {
      opacity: .15;
    }

    &:checked {
      background: $main;
      transform: scale(1);
    }

    &:checked~label:after {
      opacity: 1;
      border-color: $main;
    }
  }

  &--white label:before {
    border: 1px solid #fff
  }

  &--light label:before {
    border: 1px solid #3f3f3f30;
  }
}

/* -------------------------------------------------------------------------- */
/*                                    FILE                                    */
/* -------------------------------------------------------------------------- */
.form-file {
  input {
    display: none;
    appearance: none;
  }

  label {
    display: inline-block;
    padding: 10px 15px;
    background: #a31319;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    word-wrap: break-word;
  }
}

/* -------------------------------------------------------------------------- */
/*                                 SELECT LIST                                */
/* -------------------------------------------------------------------------- */
.select-list {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 3;
  background: #fff;
  padding: 5px 0;
  box-shadow: 0px 1px 3px 0px #0000003d;
  border-radius: 6px;
  border-color: transparent;
  border: 1px solid #f2f2f2;
  display: none;

  &__item {
    padding: 8px 12px;
    font-size: 14px;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;

    &:last-child {
      border-color: transparent;
    }

    &:hover {
      background: #f5f5f5;
    }
  }

  &.is-active {
    display: block;
  }
}


/* -------------------------------------------------------------------------- */
/*                                    OTHER                                   */
/* -------------------------------------------------------------------------- */
.form-group-hidden {
  transition: max-height 0.4s;
  max-height: 0;
  overflow: hidden;

  &.is-shown {
		max-height: 140px;
		overflow: inherit;
  }

  &.is-active {
    max-height: 500px;
    overflow: inherit;
  }
}

.form-editable {
  .form-editable__pencil {
    position: absolute;
    z-index: 1;
    right: 15px;
    width: 25px;
    height: 30px;
    top: 8px;
    padding: 5px;
    cursor: pointer;
  }
}




input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.error-message {
  color: #9a2b2b;
}

.success-message {
  color: #22b30f;
}

.hide-spinner {

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}



.is-uneditable {
  pointer-events: none !important;
  border-color: transparent !important;
  padding-left: 0 !important;

  input,
  textarea {
    background: transparent;
    pointer-events: none !important;
    border-color: transparent !important;
    padding-left: 0 !important;
  }
	.file-remover{
		opacity: 0;
		pointer-events: none;
	}
}

// Expanding box of text
.after-expand {
  display: none;
}

.is-expanded {
  .expand-box {
    height: auto !important;
  }

  .before-expand {
    display: none;
  }

  .after-expand {
    display: block;
  }
}


input:checked + label{
  .tile--hoverable-border{
		background: #a31319;
		h6{
			color:#fff !important;
		}
  }
}

.form-input-border-color{
	input[value=""] {
  	@apply border-secondary;
	}
}