.notification-toggler{
  z-index: 3;
  position: relative;
}

.notification-wrapper{
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 97;
  background: #fff;
  width: 320px;
  max-height: 400px;
  opacity: 0;
  transition: transform 0.25s, opacity 0.25;
  pointer-events: none;
  transform:translateY(50px);
  max-width: calc(100vw - 100px);
  border-radius:5px;
  &:before{
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    right: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent $main transparent;
  }

  &.is-active{
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
  }
}

.notification-counter{
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
}

.notification-header{
  padding: 5px 15px;
  border-bottom: 1px solid #EFF3F4;
  background: $main;
  color: #fff;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  &__read{
    font-family: 'Roboto Condensed';
    user-select: none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    &:hover{
      color: #000;
      cursor:pointer;
    }
  }
}

.notification-scroll{
  overflow-y:scroll;
  position:relative;
  max-height: 200px;
  margin-right:10px;
  &::-webkit-scrollbar{width: 4px; margin-right:10px}
  &::-webkit-scrollbar-track{background: #fff}
  &::-webkit-scrollbar-thumb {
    background: #E9E5DD;
    border-radius: 5px
  }
  &::-webkit-scrollbar-thumb:hover{filter:brightness(0.8)}
}

.notification-item{
  display:flex;
  padding: 8px 10px;
  border-bottom: 1px solid #EFF3F4;
  line-height: 20px;
  justify-content: space-between;
  p{margin:0}
  &__icon{
    background: $sec;
    border-radius: 26px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-right:15px;
  }
  &__text{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size:12px;
  }
  .notification-delete{
    margin-left: auto;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;
  }
  &.is-active{
    background: #EFF1F2;
    font-weight: 700;
    .notification-delete{
      pointer-events: all;
      opacity: 1;
    }
  }
}
.notification-footer{
  font-size: 12px;
  color: $main;
  font-weight: 600;
  text-align:center;
  padding: 8px 10px;
}

.notification-loader{
  position: fixed;
  top: 0;
  background: #f2f2f2b3;
  height: 100%;
  width: 100%;
  display:none;
  align-items:center;
  justify-content: center;
  &.is-active{
    display:flex;
    pointer-events: all;
  }
}


@media only screen and (max-width: 992px) {
  .notification-wrapper{
    left:60px;
  }
  .notification-item__icon{
    width: 51px;
    height: 50px;
  }
  .notification-scroll{
    padding: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .notification-scroll{
    max-height: 180px;
  }
  .notification-wrapper{
    box-shadow: 0 3px 10px 3px #0000002e, 0 3px 6px 2px #00000009;
    max-width: calc(100vw - 60px);
  }
  .notification-header{
    padding: 15px 20px;
    &__read{
      display:none
    }
  }
  .notification-item{
    padding: 10px;
    &__icon{
      display:none
    }
  }
}