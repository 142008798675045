.site-footer {
  color: #3F3F3F;
  font-size: 16px;
  background: #E2E4E5;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.site-footer__right a {
  display: initial
}

.site-footer__left span {
  margin: 0 8px
}

.site-footer__right a {
  font-weight: 700
}

.site-footer__right {
  margin-left: auto
}

@media only screen and (max-width: 756px) {
  .site-footer {
    font-size: 14px
  }
}

// @media only screen and (max-width: 560px) {
//   .site-footer {
//     flex-direction: column;
//     -webkit-box-pack: center;
//     justify-content: center
//   }

//   .site-footer__left,
//   .site-footer__right {
//     margin: 0;
//     text-align: center
//   }

//   .site-footer__left {
//     margin-bottom: 5px
//   }
// }