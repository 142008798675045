body{
  background: #EFF1F2;
}
.capitalize{
  &:first-letter{
    text-transform: capitalize;
  }
}

.link{
  text-decoration: underline;
  &:hover{
    text-decoration: none;
  }
}

.main{
  width: calc( 100% - 300px );
  margin-left: auto;
  display: flex;
  flex-direction: column;
  height:100vh;
  overflow-y:scroll;
  &--auto{
    height: auto;
  }
}
.main-pad{
  padding: 30px 20px;
  flex: 1;
}

.small-font{
  font-size:14px;
}
.red{
  color: $main;
}
.green{
  color: $third;
}

.background-image{
  background-size:cover !important;
  background-position: center !important;
}


.rect{
  width:20px;
  height:20px;
  display:inline-block;
  border-radius: 5px;
  &--blue{
    background: #BDD7EE;
  }
  &--green{
    background: #E2F0D9;
  }
  &--orange{
    background: #FBE5D6;
  }
}

.overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events:none;
  z-index:1;
  &.is-active{
    pointer-events:all
  }
}

.avatar-replace{
  &:hover{
    cursor: pointer;
  }
}


@media only screen and (max-width: 1200px) {
  .main{
    // width: calc( 100% - 60px);
    width: 1190px;
    overflow-y: scroll;
    left: 60px;
    position: relative;
  }
}

