.pagination{
  display:flex;
  justify-content: flex-end;
  &__item{
    margin-left:3px;
    margin-right:3px;
    &.is-active{
      font-weight: 700
    }
  }
}

@media only screen and (max-width: 575px) {
  .pagination-total{display:none}
  .pagination{
    text-align:center;
    margin-top: 2vmin;
  }
}