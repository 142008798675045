.gallery-item{
  border-radius:5px;
  height: 300px;
  overflow: hidden;
  position:relative;
  &__delete{
    position:absolute;
    right: 20px;
    top: 20px;
    transform: translateX(60px);
    transition: transform 0.4s;
    display:flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    background: #fff;
    border-radius: 5px;
    &:hover{
      cursor: pointer;
      opacity: 1;
    }
  }
  &__zoom{
    position:absolute;
    left: 10px;
    top: 10px;
    height:20px;
    width:20px;
    transform: translateX(-60px);
    transition: transform 0.4s;
  }
  &__image{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover{
    .gallery-item__zoom{
      transform: translateX(0);
    }
    .gallery-item__delete{
      transform: translateX(0);
    }
  }
}