.auth-section{
  padding-left: 20vmin;
  padding-right: 20vmin;
  background-repeat: no-repeat;
  background-size:cover;
  height: 100vh;
  width: 100vw;
}
.form-wrapper{
  background: #fff;
  height:70vh;
  padding: 50px;
  display:flex;
  align-items:center;
}
.logo-auth{display:block; margin: 0 auto; width: 200px}
.logo__image{width:100%}
input{width:100%}

