.loader-wrapper{
  &.is-active{
    .loader{
      display:block;
    }
    .loaded-content{
      display: none;
    }
  }
  .loader{
    display:none
  }
}

.loader{
  &--xxs{
    width: 20px;
  }
  &--sm{
    width:100px;
  }
}