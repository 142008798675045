.modal-container {
	&.is-active {
		.modal{top: 50%;opacity: 1;}
		.modal-overlay {display: block;}
	}
}
.modal-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	display: none;
	background: #00000030;
}

.modal{
	background: #fff;
	color: #000;
	position: fixed;
	top: -500px;
	left: 50%;
	width: 400px;
	max-width: 90%;
	min-height: 150px;
	z-index: 1001;
	transition: transform 0.3s ease-in-out;
	opacity: 0;
	transform: translateY(-50%) translateX(-50%);
	box-shadow: -5px 8px 10px #00000040;
	border-radius: 1vmin;
	display: flex;
  flex-direction: column;
	&__header {
		padding: 15px;
		padding-right: 40px;
		color: $sec;
		border-bottom: 1px solid;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&--auto{
		min-height: auto;
	}
	&__content {
		padding: 15px;
	}
	&__footer{
		margin-top:auto;
		padding: 15px;
	}

	&__cancel{
		position: absolute;
		top: 14px;
		right: 0;
		cursor: pointer;
		transform: rotate(45deg);
		width: 20px;
		height: 20px;
		margin-right: 15px;
		&:after, &:before{
			content: "";
			position: absolute;
			background-color: $main;
			transition: transform .25s ease-out;
		}
		&:after {
			top: 50%;
			left: 0;
			width: 100%;
			height: 4px;
			margin-top: -2px;
		}
		&:before {
			top: 0;
			left: 50%;
			width: 4px;
			height: 100%;
			margin-left: -2px;
		}
	}
}

.modal.is-loading{
	background-image: url('/client/images/loader.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 150px;
	.modal__content, .modal__footer, .modal__header{opacity:0}
}
.modal.is-success, .modal.is-failed{
	.btn--proceed{display:none}
}


.modal--upload{
	max-width:100%;
  width: 1625px;
  height: 90%;
}

.modal-gallery{
	display:flex;
	flex-wrap: wrap;
	overflow: scroll;
	max-height: 100%;
	&::-webkit-scrollbar{width: 4px; margin-right:10px}
  &::-webkit-scrollbar-track{background: #fff}
  &::-webkit-scrollbar-thumb {
    background: #E9E5DD;
    border-radius: 5px
  }
  &::-webkit-scrollbar-thumb:hover{filter:brightness(0.8)}
	&__item{
		margin:3px;
		width:100px; height:170px;
		box-sizing: content-box;
		img{object-fit: contain;height:100px; width:100%}
		div{
			font-size: 14px;
			overflow: hidden;
			word-break: break-all;
			height: 70px;
			padding: 5px;
			width: 100%;
		}
		&.is-active{
			border: 3px solid $main;
    	margin: 0;
		}
	}
}

.modal--loadable{
	&.is-success{
		.btn--proceed{display:none}
		.btn--red{@extend .btn--green; &:hover{background: #E9E5DD;}}
	}
}

@media only screen and (max-width: 1620px) {
	.modal--upload{
		width: 1444px;
	}
}
@media only screen and (max-width: 1450px) {
	.modal--upload{
		width: 1290px;
	}
}
@media only screen and (max-width: 1310px) {
	.modal--upload{
		width: 1134px;
	}
}
@media only screen and (max-width: 1150px) {
	.modal--upload{
		width: 976px;
	}
}
@media only screen and (max-width: 990px) {
	.modal--upload{
		width: 820px;
	}
}
@media only screen and (max-width: 830px) {
	.modal--upload{
		width: 666px;
	}
}
@media only screen and (max-width: 680px) {
	.modal--upload{
		width: 424px;
	}

	.modal-gallery__item{
		width:70px;
		height:70px;
	}
}

@media only screen and (max-width: 450px) {
	.modal-upload{
		width: 320px;
	}
	.modal-gallery__item{
		width: 65px;
    height: 65px;
	}
}