.top-bar{
  background:#fff;
  z-index: 101;
  padding: 15px;
  height: 80px;
  border-radius: 5px;
}

.user-profile-wrapp{
  height: 100%;
  display: flex;
  align-items: center;
  &:hover{
    background: #F8FAFA;
    .user-profile__dropdown{
      pointer-events: all;
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
}
.user-profile{
  display:flex;
  align-items:center;
  background: $sec;
  color: #3F3F3F;
  padding:12px;
  border-radius: 5px;
  position: relative;
  cursor:pointer;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  &__name{
    margin-right:10px;
    line-height:20px;
  }
  &__avatar{
    width: 25px;
  }
}
.user-dropdown{
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  transform: translateY(20px);
  box-shadow: 0px 5px 6px 0px #0000000d, 3px 5px 10px 0 rgba(154, 161, 171, 0.2);
  border-radius: 6px;
  width: 100%;
  min-width: 125px;
  background: #fff;
  list-style: none;
  padding: 0;
  text-align: center;
  transition: transform 0.1s, opacity 0.1s;
  opacity: 0;
  pointer-events: none;
  z-index: 3;
  &:after{
    z-index: -1;
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: #999;
    transform: rotate(45deg);
    top: -2px;
    left: 12px;
    box-shadow: -5px -5px 5px 0px rgba(158, 158, 158, 0.2);
  }
  &:before{
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    left: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #fff transparent;
  }
  &__item{
    padding: 10px;
    display: block;
    z-index: 3;
    background: #fff;
    &:hover{
      font-weight: 700;
      background: #EFF1F2;
    }
  }

  &.is-active{
    opacity: 1;
    pointer-events: all;
    transform: none;
  }
}

@media only screen and (max-width: 992px) {
	
  .user-profile-wrapp{
    padding:0 10px;
  }
  .user-profile{
    padding: 10px;
    &__name{
      display: none;
    }
    &__dropdown{
      left: inherit;
      right: 0;
      transform:translateY(50px);
    }
  }
  .user-profile-wrapp:hover .user-profile__dropdown{
    transform:translateY(0);
  }
  .top-bar__icon{
    width: 40px;
    height: 40px;
  }
}


@media only screen and (max-width: 575px) {
  .table-col{
    padding:6px;
    font-size: 14px;
  }
}