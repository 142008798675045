.choices--no-border{
  .choices__inner{
    background-color: transparent;
    border: none;
  }
  .choices__item--selectable{
    padding-right:60px;
  }
  .choices[data-type*=select-one]:after{
    background-image: url('/client/images/right-arrow.svg');
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    border: none;
    background-position: center;
    transform: translateY(-50%);
  }
  .choices[data-type*=select-one].is-open:after{
    transform:rotate(180deg) translateY(calc(50% - 5px));
  }
}

.choices__inner{
  border-radius: 5px;
  background-color: transparent;
}

.choices--no-bg{
  .choices__inner{
    background-color: transparent;
  }
}

.choices__list--multiple .choices__item{
  background: $main;
  border-color: $main;
}

.choices__list--single .choices__item {
  font-size: 16px;
}